<template>
  <hr />
</template>

<style scoped>
hr {
  border: 0;
  width: 100%;
  border-top: 2px solid #f6f6f6;
  margin: 0;
}
</style>
